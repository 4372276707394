exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galeri-tsx": () => import("./../../../src/pages/galeri.tsx" /* webpackChunkName: "component---src-pages-galeri-tsx" */),
  "component---src-pages-hakkimizda-tsx": () => import("./../../../src/pages/hakkimizda.tsx" /* webpackChunkName: "component---src-pages-hakkimizda-tsx" */),
  "component---src-pages-iletisim-tsx": () => import("./../../../src/pages/iletisim.tsx" /* webpackChunkName: "component---src-pages-iletisim-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-urun-detay-tsx": () => import("./../../../src/pages/urun-detay.tsx" /* webpackChunkName: "component---src-pages-urun-detay-tsx" */),
  "component---src-pages-urunler-tsx": () => import("./../../../src/pages/urunler.tsx" /* webpackChunkName: "component---src-pages-urunler-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

